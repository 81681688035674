import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import LinkButton from './LinkButton';
import styles from './styles/LayoutMin.module.scss';
import Logo from './Logo';

const LayoutMin = ({
  appUrl,
  basePath = '',
  className,
  children,
  showSignIn,
  transparent,
}) => {
  return (
    <div className={classnames(styles.container, className)}>
      <nav
        className={classnames(
          styles.nav,
          transparent && styles.transparent,
          'navbar justify-content-between fixed-top',
        )}
      >
        <div className="container">
          <Link
            className={classnames('navbar-brand', styles.brand)}
            to={basePath}
          >
            <Logo />
          </Link>
          {showSignIn && (
            <LinkButton
              className={styles.signIn}
              href={appUrl}
              background="#5E9CFF"
              track
            >
              Sign in
            </LinkButton>
          )}
        </div>
      </nav>
      {children}
    </div>
  );
};

export default LayoutMin;
