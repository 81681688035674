import React from 'react';
import LinkButton from '../components/LinkButton';
import LayoutMin from '../components/LayoutMin';
import SEO from '../components/seo';
import { getParameterByName } from '../common/utils';
import styles from './styles/consent.module.scss';
import QSvg from '../images/q/q-cross.svg';

const successMsg = (
  <>
    <div className={styles.title}>
      <b>
        Great! We’ll notify your Adviser that
        <br />
        you have accepted the invitation.
      </b>
    </div>
    <div>You’re one step closer to having all your insurance in one place.</div>
    <div>
      <b>
        Your Adviser will create your account and load your insurance policies
        onto
        <br />
        your own dashboard for you. You’ll be notified by them when it is done.
      </b>
    </div>
  </>
);

const errorMsg = (
  <>
    <div className={styles.title}>
      <b>Oops, something went wrong!</b>
    </div>
    <div>
      <b>Please contact your Adviser, they should be able to help you out.</b>
    </div>
  </>
);

const Consent = () => {
  let error = false;
  if (typeof window !== 'undefined') {
    const queryError = getParameterByName('error', window.location.href);
    if (queryError) error = true;
  }

  return (
    <LayoutMin>
      <SEO title="Consent" />
      <div className={styles.container}>
        <QSvg className={styles.q} />
        {error ? errorMsg : successMsg}
        <div>In the mean time, learn more about Quashed!</div>
        <LinkButton href="/" background="#34363E" className={styles.button}>
          Learn more
        </LinkButton>
      </div>
    </LayoutMin>
  );
};

export default Consent;
